<template>
  <div class="header-mobile__wrapper">
    <div class="header-mobile__gap-filler"></div>

    <a-header-mobile-top-section
      :is-menu-expanded="isMenuExpanded"
      @input="changeMenuVisibility"
    />

    <collapse-transition>
      <div v-show="isMenuExpanded" v-on-clickaway="handleExpandedMenuClickaway">
        <a-header-mobile-expanded
          :is-menu-expanded="isMenuExpanded"
          :menu-list="navigationItems"
          @onCloseMenu="closeMenu"
          @search="closeMenu"
        />
      </div>
    </collapse-transition>
  </div>
</template>

<script>
import { directive as onClickaway } from 'vue-clickaway'

import { propValidator, PROP_TYPES } from '@/utils/validators'
import AHeaderMobileTopSection from './AHeaderMobileTopSection'
import AHeaderMobileExpanded from './AHeaderMobileExpanded'
import { hydrationHelpers } from '@/utils/mixins/hydrationHelpers'
import { HEADER_MOBILE_MENU_OPEN_BTN_ATTRIBUTE } from 'enums/data-attributes'
import { THROTTLE_DEFAULT_DURATION } from 'enums/settings'
import { mapGetters } from 'vuex'
import { hasParentWith } from '@fmpedia/helpers'

export const CLOSE_MOBILE_HEADER_MENU_EVENT = 'CLOSE_MOBILE_HEADER_MENU_EVENT'
const SCROLL_TO_HEADER_DURATION = 300

export default {
  name: 'AHeaderMobile',
  mixins: [hydrationHelpers],
  components: { AHeaderMobileTopSection, AHeaderMobileExpanded },
  props: {
    navigationItems: propValidator([PROP_TYPES.ARRAY])
  },
  directives: { onClickaway },
  data() {
    return {
      isMenuExpanded: false,
      timeoutId: null
    }
  },
  computed: {
    ...mapGetters({
      isHeaderAtTop: 'isHeaderAtTop',
      scrollTop: 'scrollTop',
      isPageNavigationInProgress: 'isPageNavigationInProgress'
    })
  },
  watch: {
    isPageNavigationInProgress(newVal) {
      if (!newVal) return

      this.closeMenu()
    }
  },
  methods: {
    closeMenu() {
      if (!this.isMenuExpanded) return

      this.$_hydrationHelpers_showScroll()
      this.isMenuExpanded = false
    },
    changeMenuVisibility(val) {
      clearTimeout(this.timeoutId)

      if (!val) {
        this.closeMenu()
        return
      }

      if (!this.isHeaderAtTop) {
        this.$scrollTo(`.header-mobile__wrapper`, SCROLL_TO_HEADER_DURATION)

        /**
         * TODO investigate why we need to wait for THROTTLE_DEFAULT_DURATION
         * (or longer) before hiding the scroll (otherwise there is a content
         * jumping bug when mobile header menu opens)
         */
        this.timeoutId = setTimeout(async () => {
          this.isMenuExpanded = val
        }, SCROLL_TO_HEADER_DURATION + THROTTLE_DEFAULT_DURATION)

        return
      }

      this.isMenuExpanded = val
    },
    checkIfMenuOpenBtnClicked(e) {
      return hasParentWith(e.target, {
        attribute: HEADER_MOBILE_MENU_OPEN_BTN_ATTRIBUTE
      })
    },
    handleExpandedMenuClickaway(e) {
      if (this.checkIfMenuOpenBtnClicked(e) || !this.isMenuExpanded) return

      this.closeMenu()
    }
  },
  beforeMount() {
    this.$bus.$on(CLOSE_MOBILE_HEADER_MENU_EVENT, this.closeMenu)
  },
  mounted() {
    this.$emit('mounted')
  },
  beforeDestroy() {
    this.$bus.$off(CLOSE_MOBILE_HEADER_MENU_EVENT)
    clearTimeout(this.timeoutId)
  }
}
</script>

<style lang="scss" scoped>
.header-mobile__wrapper {
  background: $c--header;
  color: white;
  padding: 0 20px;

  .header-mobile__gap-filler {
    @include top-gap-filler($background: $c--black, $z-index: 1);
  }
}
</style>
